import React from 'react';
import "./styles.css";
import IosArrowBack from "react-ionicons/lib/IosArrowBack";

export default function CabecalhoPagina(props) {
    return <div className={"CabecalhoPagina page_header_padding"}>
        <div className="container width-100">
            <span className={"button"} onClick={props.backCallback}>
                <IosArrowBack fontSize={"28px"}/>
            </span>
            <div>
                <h3>{props.titulo}</h3>
            </div>
        </div>
    </div>;
}

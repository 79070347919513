import React, {useState} from 'react';
import "./styles.css";
import IosArrowBack from "react-ionicons/lib/IosArrowBack";

export default function Modal(props) {
    const [modal_in, setModalIn] = useState(true);
    const [animating, setAnimating] = useState(true);

    const hideModal = () => {
        setModalIn(false);
        setAnimating(true);
        setTimeout(window.App.removeOverlay, 300);
    };

    setTimeout(() => setAnimating(false), 300);

    return (<div className={"Modal"}>
        <div className={"background_top " + (animating ? " animated " + (modal_in ? "fadeIn" : "fadeOut") : '')}/>
        <div className={"background " + (animating ? " animated " + (modal_in ? "fadeIn" : "fadeOut") : '')}/>
        <div className={"content_wrapper " + (animating ? " animated " + (modal_in ? "fadeInUpLong" : "fadeOutDown") : '')}>
            {!props.fullscreen && <div className={"modal_header"}>
                <div className={"container width-100"}>
                    <span className={"button"}>
                        <IosArrowBack className={"close"} fontSize={"28px"} onClick={hideModal}/>
                    </span>
                    <div>
                        <h3>{props.title}</h3>
                    </div>
                </div>
            </div>}
            <div className={"modal_content" + (props.fullscreen ? " fullscreen" : "")}>
                {props.content && props.content(hideModal)}
                {props.children}
            </div>
        </div>
    </div>);
}

import React from 'react';
import "./styles.css";
import filter from "./filter.svg"

export default class FilterToggle extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={"FilterToggle noselect"} onClick={this.props.onClick}>
                <div className={"inner"}>
                    <img src={filter}/>
                    <div>
                        <p>Filtrado por:</p>
                        <h4>{this.props.active ? this.props.activeText : this.props.unactiveText}</h4>
                    </div>
                </div>
            </div>
        );
    }
}
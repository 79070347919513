import React from "react";
import GoogleMapReact from 'google-map-react';
import './styles.css';
import IosPin from "react-ionicons/lib/IosPin";
import IosArrowBack from "react-ionicons/lib/IosArrowBack";
import TextTransition, {presets as text_transition_presets} from "react-text-transition";

class MapMarker extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div tabIndex="-1" className={"MapMarker"}>
            <IosPin fontSize="62px" color={"#011146"}/>
        </div>;
    }
}

export default class Map extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            center: {
                lat: 38.74884927,
                lng: -9.15389358
            },
            zoom: 19
        };
    }

    onChange({bounds, size, zoom}) {
        if (zoom !== this.zoom) {
            this.zoom = zoom;
            let pxOffset = size.height * 0.12;
            this.setState({
                latOffset: pxOffset * (bounds.nw.lat - bounds.se.lat) / size.height
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.address && props.address.marker)
            return {
                center: {
                    lat: parseFloat(props.address.marker.lat),
                    lng: parseFloat(props.address.marker.lng)
                },
                marker: props.address.marker
            };
        return null;
    }

    render() {
        let {center, zoom} = this.state;

        center.lat -= (this.state.latOffset || 0);

        let title = null;
        if (this.props.address && this.props.address.title)
            title = <div className={"MapHeader noselect hideWithoutMap page_header_padding"}>
                <div className={"container width-100"}>
                    <span className={"button"}>
                        <IosArrowBack fontSize={"28px"} onClick={() => window.App.hideMap()}/>
                    </span>
                    <div>
                        <h3>
                            <TextTransition
                                text={this.props.address.title}
                                springConfig={text_transition_presets.gentle}
                            />
                        </h3>
                        <h4>
                            <TextTransition
                                text={this.props.address.subtitle}
                                springConfig={text_transition_presets.gentle}
                            />
                        </h4>
                    </div>
                </div>
            </div>;
        return (
            <div className={"Map"}>
                {title}
                <GoogleMapReact
                    options={{
                        fullscreenControl: false,
                        zoomControl: false
                    }}
                    onChange={(a) => this.onChange(a)}
                    bootstrapURLKeys={{key: "AIzaSyDsurKWNXyTnkFhR9lTzx0GrfuEY0bhslc"}}
                    center={center}
                    defaultZoom={zoom}>
                    {this.state.marker && <MapMarker lat={this.state.marker.lat} lng={this.state.marker.lng} updateListener={this.updateListener}/>}
                </GoogleMapReact>
            </div>
        );
    }
}

import * as firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCzyn0ZDMvZIkNA7SnelwSoe3uVEVjA4uY",
    authDomain: "appx-jipe.firebaseapp.com",
    databaseURL: "https://appx-jipe.firebaseio.com",
    projectId: "appx-jipe",
    storageBucket: "appx-jipe.appspot.com",
    messagingSenderId: "291440925270",
    appId: "1:291440925270:web:7057829e1bd0760a82b9f7"
});
let messaging = null;
try {
    messaging = initializedFirebaseApp.messaging();
} catch (e) {
}
export {messaging};
import React from "react";
import Connection from "../../Connection";
import "./styles.css";
import 'react-html5-camera-photo/build/css/index.css';

class Feedback extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            feedback: ""
        };
    }

    submeter = () => {
        Connection.post("utilizador/submeter_feedback", {
            feedback: this.state.feedback
        }, (data) => {
            this.props.onSubmit && this.props.onSubmit();
        })
    };

    render() {
        return <div className={"Feedback container"}>
            <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                <p className={"instrucoes"}>Encontrou algum erro? Tem alguma sugestão para melhorar a aplicação? Partilhe connosco:</p>
                <div className={"formulario texto_livre"}>
                    <textarea autoFocus={true} onChange={(e) => {
                        this.setState({feedback: e.target.value});
                    }}/>
                </div>
            </div>
            <div className={"text-center"} style={{marginTop: 30}}>
                <button className={"btn btn-primary " + (this.state.feedback.trim() !== '' ? "" : "disabled")} onClick={this.submeter}>Enviar</button>
            </div>
        </div>;
    }
}

export default Feedback;
import React from "react";
import Connection from "../../Connection";
import moment from 'moment';
import 'moment/locale/pt';
import Loading from "../../components/loading";
import './index.css';
import IosCheckmark from "react-ionicons/lib/IosCheckmark";
import IosWarning from "react-ionicons/lib/IosWarning";
import IosInformationCircleOutline from "react-ionicons/lib/IosInformationCircleOutline";
import $ from "jquery";
import General from "../../General";
import Modal from "../../components/modal";
import Sessao from "../sessao";

moment.locale("pt");

class PersonalizarPrograma extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editar_slot: this.props.slot || null,
            slots: null,
            selecoes: null,
            confirm: null,
            loading: false
        };

        this.obterSessoesOpcionais();
    }

    obterSessoesOpcionais() {
        Connection.get("sessoes/opcionais", {agrupar: true, slot: this.state.editar_slot}, (data) => {
            let selecoes = {};
            data.registos.forEach((sessao_selecionada) => {
                selecoes[sessao_selecionada.slot] = sessao_selecionada.id;
            });
            this.setState({
                slots: data.sessoes,
                selecoes: this.state.selecoes || selecoes,
                selecao_inicial: this.state.selecao_inicial || selecoes,
                slotsEscolhidos: Object.keys(selecoes)
            });
        });
    }

    async guardar() {
        this.setState({loading: true}, () => {
            $(".Modal .modal_content").animate({scrollTop: $(".PersonalizarPrograma").height()}, 1000);
        });
        let sessoes_escolhidas = Object.values(this.state.selecoes);
        let result = await Connection.post("sessoes/registar", {sessoes: sessoes_escolhidas, confirmar_registo: !!this.state.confirm});
        if (result && result.resultado === 'REGISTOS_EXISTENTES') {
            this.setState({
                loading: false,
                confirm: <div style={{marginTop: 40}}>
                    <IosWarning color={"#ffa500"}/>
                    <h4 style={{fontWeight: "normal", marginBottom: 15}}>Clique em "Confirmar" para validar o registo na sessão selecionada e para remover o registo da seguinte sessão:</h4>
                    {result.sessoes.map((sessao) => <p><strong>{moment(sessao.inicio).format("[Dia ]d [às] H:mm")}</strong><br/>{sessao.titulo}</p>)}
                </div>
            })
        } else if (result && result.resultado === 'SESSOES_LOTADAS') {
            this.obterSessoesOpcionais();
            this.setState({
                loading: false,
                confirm: <div style={{marginTop: 40}}>
                    <IosWarning color={"#ffa500"}/>
                    <h4 style={{fontWeight: "normal", marginBottom: 15}}>As seguites sessões não têm vagas. Escolha uma sessão alternativa e tente novamente.</h4>
                    {result.sessoes.map((sessao) => <p><strong>{moment(sessao.inicio).format("[Dia ]d [às] H:mm")}</strong><br/>{sessao.titulo}</p>)}
                </div>
            })
        } else {
            General.success("Escolheu com sucesso a(s) sessões em que pretende participar");
            this.props.onSuccess && this.props.onSuccess();
        }
    }

    render() {
        if (!this.state.slots)
            return <Loading/>;

        return <div className={"PersonalizarPrograma"}>
            {this.state.editar_slot ?
                <div className={"header container"}>
                    <p>Escolha de entre as opções que se seguem a sessão em que pretende participar.</p>
                    <p>Tenha em atenção que ao selecionar outra sessão poderá perder o registo anterior.</p>
                </div> :
                <div className={"header container"}>
                    <p>Existem atividades a decorrer em paralelo e com limites de participantes.</p>
                    <p>Aconselhamos a fazer a escolha brevemente.</p>
                </div>}
            <div className={"container sessoes"}>
                {this.state.slots.map((dia) => {
                    let slots = this.props.ocultarSelecionados ? dia.slots.filter(slot => !this.state.slotsEscolhidos.includes(slot.slot.id + "")) : dia.slots;
                    if (slots.length === 0)
                        return null;
                    return <div className={"bloco_dia"}>
                        <h3 className={"data_slot color-primary"}>{moment(dia.dia).format("D [de] MMMM")}</h3>
                        {slots.map((slot) => {
                            let inicio = moment(slot.slot.inicio).format("H [horas e] m [minutos]");
                            inicio = inicio.replace("e 0 minutos", "");
                            return [
                                <h4 className={"hora_slot"}>{inicio}</h4>,
                                slot.temas.map((tema) => {
                                    return tema.categorias.map((categoria) => {
                                        return categoria.sessoes.map((sessao) => {
                                            let estado = "";
                                            //Está selecionado
                                            if (this.state.selecoes[slot.slot.id] === sessao.id)
                                                estado = "active";
                                            else if (!this.state.selecoes[slot.slot.id] && sessao.predefinicao_slot)
                                                estado = "default";
                                            return <div className={"sessao" + (sessao.vagas === 0 && this.state.selecao_inicial[slot.slot.id] !== sessao.id ? " desativada" : "")}>
                                                <div onClick={() => {
                                                    window.App.addOverlay(<Modal fullscreen key={"modal_detalhes_sessao_" + sessao.id} content={hide => <Sessao id={sessao.id} onBack={hide}/>}/>);
                                                }}>
                                                    <h3><IosInformationCircleOutline color={"gray"} fontSize={"15px"}/> {sessao.titulo}</h3>
                                                    <h4>{[categoria.categoria.designacao, tema.tema.designacao].join(" - ")}</h4>
                                                    {sessao.vagas !== null && <p className={sessao.vagas > 10 ? "text-success" : "text-warning"}>{sessao.vagas} vagas</p>}
                                                </div>
                                                <div className={"botao"} onClick={() => {
                                                    let selecoes = {...this.state.selecoes};
                                                    selecoes[slot.slot.id] = sessao.id;
                                                    this.setState({selecoes: selecoes});
                                                }}>
                                                <span className={"toggle-sessao " + estado}>
                                                    <IosCheckmark color={"white"} fontSize={"29px"}/>
                                                </span>
                                                </div>
                                            </div>
                                        });
                                    });
                                })
                            ];
                        })}
                    </div>;
                })}
                {this.state.confirm}
                {this.state.loading ? <Loading/> :
                    <div className={"text-center"} style={{marginTop: 30}}>
                        <button className="btn btn-primary" onClick={() => this.guardar()}>{this.state.confirm ? "Confirmar" : "Guardar"}</button>
                    </div>}
            </div>
        </div>
    }
}

export default PersonalizarPrograma;
import React, {useState} from "react";
import Connection from "../../Connection";
import "./styles.css";
import Loading from "../../components/loading";
import IosCheckmark from "react-ionicons/lib/IosCheckmark";
import Camera, {FACING_MODES, IMAGE_TYPES} from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import $ from "jquery";
import IosSync from "react-ionicons/lib/IosSync";
import IosExpand from "react-ionicons/lib/IosExpand";
import IosContract from "react-ionicons/lib/IosContract";
import placeholderPhoto from "./placeholder_photo.svg";

const parse = require('html-react-parser');

function Formulario(props) {
    const [respostas, setRespostas] = useState({});
    const [state, setState] = useState({});
    let {desafio} = props;

    let validate = (respostas, valid) => {
        props.onChange && props.onChange({
            valid: valid,
            content: respostas
        });
    };

    let lerFicheiro = ({target}) => {
        const file = target.files[0];
        const reader = new FileReader();

        reader.addEventListener("load", function () {
            let _respostas = {...respostas, imagem: reader.result, imagem_submetida: true};
            setRespostas(_respostas);
            validate(_respostas, true);
        });

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    switch (desafio.formato) {
        case "esc_multipla":
            if (!desafio.perguntas)
                return null;
            return <div className={"formulario esc_multipla"}>
                {desafio.perguntas.map(pergunta => <div className={"pergunta"} key={"pergunta_" + pergunta.id}>
                    <h4>{pergunta.designacao}</h4>
                    <div className={"opcoes"}>
                        {pergunta.opcoes.map(opcao => <div className={"opcao"} key={"opcao_" + opcao.id} onClick={() => {
                            let _respostas = respostas;
                            _respostas[pergunta.id] = {opcao: opcao.id, texto: pergunta.designacao + " - " + opcao.designacao};
                            setRespostas({..._respostas});
                            validate(_respostas, Object.keys(_respostas).length === desafio.perguntas.length);
                        }}>
                            <p>{opcao.designacao}</p>
                            <span className={"select-opcao" + (respostas[pergunta.id] && respostas[pergunta.id].opcao === opcao.id ? " active" : "")}>
                                <IosCheckmark color={"white"} fontSize={"21px"}/>
                            </span>
                        </div>)}
                    </div>
                </div>)}
            </div>;
        case "imagem":
            return <div className={"formulario imagem"}>
                <div className={"wrapper_widget_imagem" + (state.fullscreen && !respostas.imagem ? " fullscreen" : "")}>
                    <div className={"imagem"}>
                        {!state.cameraError && !respostas.imagem && <Camera
                            onTakePhotoAnimationDone={(dataUri) => {
                                let _respostas = {...respostas, imagem: dataUri};
                                setRespostas(_respostas);
                                validate(_respostas, true);
                            }}
                            isMaxResolution={true}
                            imageType={IMAGE_TYPES.JPG}
                            isImageMirror={!state.facingMode}
                            isSilentMode={true}
                            idealFacingMode={state.facingMode ? FACING_MODES.ENVIRONMENT : FACING_MODES.USER}
                            onCameraError={(error) => {
                                console.log(error);
                                setState({cameraError: true})
                            }}
                        />}
                        {respostas.imagem && (respostas.imagem_submetida ? <span className={"preview_imagem"} style={{backgroundImage: "url(" + respostas.imagem + ")"}}/> : <img className={"preview_imagem"} src={respostas.imagem}/>)}
                        {!respostas.imagem && state.cameraError && <div className={"placeholder"}><img src={placeholderPhoto}/></div>}
                        {!respostas.imagem && !state.cameraError && [
                            <div className={"switch"} onClick={() => setState({facingMode: !state.facingMode})}><IosSync fontSize="20px" color={"white"}/></div>,
                            <div className={"toggle_fullscreen"} onClick={() => setState({fullscreen: !state.fullscreen})}>{state.fullscreen ? <IosContract fontSize="20px" color={"white"}/> : <IosExpand fontSize="20px" color={"white"}/>}</div>
                        ]}
                    </div>
                    <div className={"text-center actions"}>
                        {!state.cameraError && <button className={"btn btn-primary btn-xs"} style={{minWidth: "50%"}} onClick={() => {
                            if (respostas.imagem) {
                                let _respostas = {...respostas, imagem: null, imagem_submetida: false};
                                setRespostas(_respostas);
                                validate(_respostas, false);
                            } else
                                $("#outer-circle").click();
                        }}>{respostas.imagem ? "Tentar novamente" : "Tirar foto"}
                        </button>}
                        <p className={"text-center " + (state.cameraError && !respostas.imagem ? "with_placeholder" : "")}><label className={"cursor-pointer"}><input type={"file"} onChange={lerFicheiro}/>Abrir galeria</label></p>
                    </div>
                </div>
            </div>;
        case "texto_livre":
            return <div className={"formulario texto_livre"}>
                <textarea autoFocus={true} onChange={(e) => {
                    let _respostas = {...respostas, texto: e.target.value};
                    setRespostas(_respostas);
                    validate(_respostas, _respostas.texto.length > 0);
                }}/>
            </div>;
    }
}

class Desafio extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            desafio: null,
            dados: null,
            valido: false
        };

        Connection.get("jogo/desafio/detalhes/" + this.props.desafio.id, null, (data) => {
            this.setState({desafio: data});
        })
    }

    submeter = () => {
        if (this.state.valido) {
            Connection.post("jogo/desafio/responder", {
                desafio: this.state.desafio.id,
                resposta: this.dados
            }, (data) => {
                this.props.onSubmit && this.props.onSubmit();
            })
        }
    };

    render() {
        let {desafio} = this.state;

        return <div className={"Desafio container"}>
            {desafio ? [
                    <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                        {desafio.instrucoes && <p className={"instrucoes"}>{desafio.instrucoes && parse(desafio.instrucoes.split("\n").join("<br/>"))}</p>}
                        <Formulario desafio={desafio} onChange={(data) => {
                            this.dados = data.content;
                            this.setState({
                                valido: data.valid
                            });
                        }}/>
                    </div>,
                    <div className={"text-center"} style={{marginTop: 30}}>
                        <button className={"btn btn-primary " + (this.state.valido ? "" : "disabled")} onClick={this.submeter}>Enviar</button>
                    </div>
                ]
                : <Loading/>}
        </div>;
    }
}

export default Desafio;
import React from "react";
import QrReader from 'react-qr-reader'
import Connection from "../../Connection";
import General from "../../General";
import IosArrowBack from "react-ionicons/lib/IosArrowBack";
import "./styles.css";
import {withRouter} from "react-router-dom";

class DesbloquearDesafio extends React.Component {
    constructor(props) {
        super(props);

        function iOSversion() {
            if (/iP(hone|od|ad)/.test(navigator.platform)) {
                // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
                var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
                return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
            }
        }

        let ios_version = iOSversion();

        this.state = {
            delay: 500,
            qrLegacy: false,
            ios_version: ios_version ? ios_version[0] : 20,
            legacyImageLoaded: false,
            codigo: ""
        };
        this.handleScan = this.handleScan.bind(this)
    }

    async handleScan(data) {
        if (data !== null) {
            try {
                let result = await Connection.post("jogo/desafio/desbloquear", {codigo: data});
                this.setState({codigo: ""});
                if (result && result.id_desafio) {
                    //Abrir desafio
                    this.props.history.push("/desafio/" + result.id_desafio);
                }
            } catch (e) {
            }
        } else if (this.state.qrLegacy) {
            General.error("Não foi encontrado nenhum código válido. Tente novamente ou insira o código manualmente.");
        }
    }

    componentDidMount() {

    }

    render() {
        let _this = this;
        return <div className={"DesbloquearDesafio"}>
            <div className={"DesbloquearDesafioHeader page_header_padding noselect " + (this.state.qrLegacy ? "" : "fixed")}>
                <div className={"container width-100"}>
                    <span className={"button"}>
                        <IosArrowBack fontSize={"28px"} onClick={() => this.props.history.goBack()}/>
                    </span>
                    <div>
                        <h3>Desbloquear Desafio</h3>
                        {!this.state.qrLegacy ?
                            <p>Aponte para um código para desbloquear o desafio associado e ganhar pontos. Se estiver com problemas, pode inserir o código manualmente:</p> :
                            <p>Clique no botão de "selecionar fotografia" para enviar uma imagem do código ou preencha o código manualmente</p>}
                        {!this.state.qrLegacy && <button className="btn btn-primary btn-stroke btn-sm" style={{marginTop: 10}} onClick={() => _this.setState({qrLegacy: true})}>Inserir código manualmente</button>}
                    </div>
                </div>
            </div>
            {_this.state.ios_version >= 10 && <div style={{display: this.state.qrLegacy ? "none" : "block"}}>
                {<QrReader
                    className={"QrCode"}
                    ref="qrreader"
                    legacyMode={this.state.qrLegacy}
                    onImageLoad={() => _this.setState({legacyImageLoaded: true})}
                    onError={(error) => _this.setState({qrLegacy: true})}
                    facingMode="environment"
                    delay={this.state.delay}
                    showViewFinder={false}
                    onScan={this.handleScan}
                    style={{width: '100%'}}/>}
            </div>}
            {_this.state.ios_version >= 10 && _this.state.qrLegacy && (<div className={"legacy"}>
                <h2>Enviar imagem do código para validar:</h2>
                <div className="text-center container">
                    <button className="btn btn-primary" type="submit" style={{marginTop: 20}} onClick={() => {
                        _this.refs.qrreader.openImageDialog();
                    }}>Selecionar fotografia
                    </button>
                </div>
                <h2 style={{marginTop: 70}}>Preencher o código manualmente:</h2>
                <div className="text-center container">
                    <input className={"codigo_manual"} onChange={(e) => {
                        this.setState({codigo: e.target.value});
                    }} value={this.state.codigo}/>
                    <button className="btn btn-primary" type="submit" style={{marginTop: 10}} onClick={() => {
                        this.handleScan(this.state.codigo)
                    }}>Validar
                    </button>
                </div>
            </div>)}
        </div>;
    }
}

export default withRouter(DesbloquearDesafio);
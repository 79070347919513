import React from "react";
import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {confirmAlert} from "react-confirm-alert";
import moment from "moment";

let iterator = 0;

let func = {
    formTransformErrors: function (errors) {
        return errors.map(error => {
            switch (error.name) {
                case "required":
                    error.message = "Este campo é obrigatório";
                    break;
                default:
                    break;
            }
            return error;
        });
    },

    formCustomFieldTemplate: function (props) {
        const {id, classNames, label, help, required, description, errors, children} = props;
        return (
            <div className={classNames + " customFieldInput"}>
                {description}
                {children}
                <label htmlFor={id}>{label}</label>
            </div>
        );
    },

    error: function (message, title, duration) {
        NotificationManager.error(message, title, duration || 4500);
    },

    success: function (message, title, duration) {
        NotificationManager.success(message, title, duration || 2000);
    },

    warning: function (message, title, duration) {
        NotificationManager.warning(message, title, duration || 3000);
    },

    alert: function (message, title, button, action) {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='container react-confirm-alert-body'>
                        {title && <h1>{title}</h1>}{typeof message === 'string' ? <p>{message}</p> : message}
                        <div className="react-confirm-alert-button-group">
                            <a className={"btn btn-success"} onClick={() => {
                                onClose();
                                action && action()
                            }}>{button || "Ok"}</a>
                        </div>
                    </div>
                )
            }
        });
    },

    prompt: function (message, title, default_text, button, callback, textarea) {
        confirmAlert({
            customUI: ({onClose}) => {
                let value = default_text;
                return (
                    <div className='container react-confirm-alert-body'>
                        {title && <h1>{title}</h1>}<p>{message}</p>{textarea ? <textarea onChange={(e) => value = e.target.value} defaultValue={value} autoFocus={true}/> : <input onChange={(e) => value = e.target.value} defaultValue={value} autoFocus={true}/>}
                        <div className="react-confirm-alert-button-group">
                            <a className={"btn btn-success"} onClick={() => {
                                onClose();
                                callback && callback(value)
                            }}>{button || "Ok"}</a>
                        </div>
                    </div>
                )
            }
        });
    },
    getNextNumber: function () {
        return ++iterator;
    },
    datasParaTexto: function (datas) {
        let datas_meses = {};
        let dias_participacao = '';
        datas.forEach((data) => {
            let _aux = moment(data);
            let mes = _aux.format("MMMM");
            if (!datas_meses[mes])
                datas_meses[mes] = [];
            datas_meses[mes].push(_aux.format("D"));
        });
        for (let mes in datas_meses) {
            if (datas_meses[mes].length === 1)
                dias_participacao += datas_meses[mes][0];
            else
                dias_participacao += datas_meses[mes].slice(0, -1).join(", ");
            if (datas_meses[mes].length > 1)
                dias_participacao += " e " + datas_meses[mes][datas_meses[mes].length - 1];
            dias_participacao += " de " + mes + ", ";
        }
        return dias_participacao.substr(0, dias_participacao.length - 2);
    }
};

window.General = func;

export default func;
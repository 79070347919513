import React from "react";
import Connection from "../../Connection";
import Loading from "../../components/loading";
import './styles.css';
import CabecalhoPagina from "../../components/cabecalho_pagina";
import IosArrowForward from "react-ionicons/lib/IosArrowForward";
import moment from "moment";
import {withRouter} from "react-router-dom";

function Notificacao(props) {
    let toggleLida = async (e) => {
        e.stopPropagation();
        await Connection.post("utilizador/notificacoes/" + (props.lido ? "marcar_nao_lida" : "marcar_lida"), {id: props.id});
        props.reload && props.reload();
    };

    return <div className={"Notificacao" + ((!props.lido || props.link) ? " cursor-pointer" : "")} onClick={props.onClick}>
        <div>
            <p><span className={"estado " + (props.lido ? "lida" : "nao_lida")} onClick={toggleLida}/>{props.assunto}</p>
            <h4>{props.conteudo}</h4>
            <p className={"time"}>{moment(props.data_envio).fromNow()}</p>
        </div>
        {props.link && <span className={"button"}>
             <IosArrowForward color={"#C4C4C4"}/>
        </span>}
    </div>
}

class Notificacoes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notificacoes: null,
        };

        this.obterNotificacoes();
    }

    onClick = async (notificacao) => {
        if (!notificacao.lido) {
            await Connection.post("utilizador/notificacoes/marcar_lida", {id: notificacao.id});
            this.obterNotificacoes();
        }
        if (notificacao.link) {
            if (notificacao.link.indexOf('://') > 0 || notificacao.link.indexOf('//') === 0)
                window.open(notificacao.link);
            else
                this.props.history.push(notificacao.link);
        }
    };

    obterNotificacoes = () => {
        Connection.get("utilizador/notificacoes", null, (data) => {
            this.setState({notificacoes: data});
        })
    };

    render() {
        let por_ler = this.state.notificacoes ? this.state.notificacoes.filter(n => !n.lido) : [];
        let lidas = this.state.notificacoes ? this.state.notificacoes.filter(n => n.lido) : [];
        return <div className={"Notificacoes"}>
            <CabecalhoPagina titulo={"Notificações"} backCallback={() => {
                this.props.history.goBack();
            }}/>
            {this.state.notificacoes ?
                <div className={"container"}>
                    <h3 className={"section-title separator light"}>Por ler</h3>
                    {por_ler.length === 0 && <p className={"section-placeholder"}>Não tem notificações por ler</p>}
                    {por_ler.map(notif => <Notificacao {...notif} reload={this.obterNotificacoes} onClick={() => this.onClick(notif)}/>)}
                    <h3 className={"section-title separator light"}>Histórico</h3>
                    {lidas.length === 0 && <p className={"section-placeholder"}>Aqui serão apresentadas as suas notificações anteriores</p>}
                    {lidas.filter(n => n.lido).map(notif => <Notificacao {...notif} reload={this.obterNotificacoes} onClick={() => this.onClick(notif)}/>)}
                </div>
                : <Loading/>}
        </div>;
    }
}

export default withRouter(Notificacoes);
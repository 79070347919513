import React from "react";
import Connection from "../../Connection";
import moment from 'moment';
import 'moment/locale/pt';
import Loading from "../../components/loading";
import './index.css';
import SlidingToggle from "../../components/sliding_toggle";
import FilterToggle from "../../components/filter_toggle";
import SlotSessoes from "../../components/slot_sessoes";
import ViewSlider from 'react-view-slider'
import {withRouter} from "react-router-dom";
import {withCookies} from "react-cookie";
import PersonalizarPrograma from "../personalizar_programa";
import Modal from "../../components/modal";

moment.locale("pt");

class Programa extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sessoes: null,
            selectedDay: parseInt(this.props.cookies.get("Programa_selectedDay") || 0),
            filterAll: false,
            loading: true
        };

        this.obterSessoes();
    }

    obterSessoes(silent) {
        if (!silent)
            this.setState({loading: true});
        let filtro = (this.state.filterAll ? "todas" : "utilizador");
        Connection.get("sessoes/programa/" + filtro, {agrupar: true}, (data) => {
            this.setState({
                selectedDay: data.length > this.state.selectedDay ? this.state.selectedDay : 0,
                sessoes: data,
                loading: false
            });
        });
    }

    changeDay(idx) {
        if (idx >= 0 && idx < this.state.sessoes.length)
            this.setState({
                selectedDay: idx
            }, () => {
                this.props.cookies.set("Programa_selectedDay", this.state.selectedDay);
            });
    }

    toggleFilter() {
        this.setState({
            filterAll: !this.state.filterAll
        }, this.obterSessoes);
    }

    mostrarDetalhesSessao(id) {
        this.props.history.push("/sessao/" + id);
    }

    editarSlot(slot) {
        window.App.addOverlay(<Modal title={"Editar sessão"} content={(hide) => {
            return <PersonalizarPrograma slot={slot} onSuccess={() => {
                hide();
                this.obterSessoes(true);
            }}/>
        }}/>);
        return false;
    }

    renderView = ({index, active, transitionState}) => {
        return <div className={"container"}>
            {this.state.sessoes[index].slots.map(slot => <SlotSessoes temas={slot.temas} slot={slot.slot} mostrarDetalhesSessao={(id) => this.mostrarDetalhesSessao(id)} onEdit={(sessao) => this.editarSlot(sessao.slot)}/>)}
        </div>;
    };

    render() {
        if (!this.state.sessoes)
            return <Loading/>;

        return <div className={"Programa"}>
            <div className={"header container page_header_padding"}>
                <SlidingToggle
                    selected={this.state.selectedDay}
                    options={this.state.sessoes.map(dia => moment(dia.dia).format("D [de] MMMM"))}
                    onChange={(index) => this.changeDay(index)}
                />
                <FilterToggle onClick={() => this.toggleFilter()} active={this.state.filterAll} unactiveText={"PESSOAL"} activeText={"COMPLETA"}/>
            </div>
            {this.state.loading ? <Loading/> : <ViewSlider
                className={"animated fadeInDown"}
                renderView={this.renderView}
                numViews={this.state.sessoes.length}
                activeView={this.state.selectedDay}
                animateHeight
            />}
        </div>;
    }
}

export default withCookies(withRouter(Programa));
import React from 'react';
import logo from "../../../img/logo.svg";
import './styles.css';

class Splashscreen extends React.Component {

    render() {
        return (
            <div className={"Splashscreen" + (this.props.children ? " withBox" : "")}>
                <div className="logo" style={{backgroundImage: "url(" + logo + ")"}}/>
            </div>
        );
    }
}

export default Splashscreen;
import React from 'react';
import "./styles.css";

export default class SlidingToggle extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedIndex: typeof props.defaultSelected !== 'undefined' ? props.defaultSelected : 0
        }
    }

    static getDerivedStateFromProps(new_props, state) {
        if (state.selectedIndex !== new_props.selected)
            return {
                selectedIndex: new_props.selected
            };
        return null;
    }

    changeSelection(newIndex) {
        this.setState({
            selectedIndex: newIndex
        });
        this.props.onChange && this.props.onChange(newIndex);
    }

    render() {
        let sliderWidth = 100 / this.props.options.length;
        return (
            <div className={"SlidingToggle noselect"}>
                <div className={"inner"}>
                    <div className={"slider btn btn-primary"} style={{marginLeft: sliderWidth * this.state.selectedIndex + "%", width: sliderWidth + "%"}}/>
                    <div className={"touch_area"}>
                        {this.props.options.map((option, idx) => {
                            return <div className={"option" + (this.state.selectedIndex === idx ? " selected" : "")} onClick={() => {
                                if (this.state.selectedIndex !== idx) {
                                    this.changeSelection(idx);
                                }
                            }}>{option}</div>
                        })}
                    </div>
                </div>
            </div>
        );
    }
}
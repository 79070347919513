import React from "react";
import './styles.css';
import CabecalhoPagina from "../../components/cabecalho_pagina";
import {withRouter} from "react-router-dom";

class Galeria extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            equipa: null,
        };
    }

    render() {
        return <div className={"Galeria"}>
            <CabecalhoPagina titulo={"Galeria Colaborativa"} backCallback={() => {
                this.props.history.goBack();
            }}/>
            <div className={"container"}>
                <p className={"section-placeholder"}>Brevemente...</p>
            </div>
        </div>;
    }
}

export default withRouter(Galeria);
import React from "react";
import './styles.css';
import CabecalhoPagina from "../../components/cabecalho_pagina";
import {withRouter} from "react-router-dom";

class Equipa extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            equipa: null,
        };
    }

    render() {
        return <div className={"Equipa page_header_padding"}>
            <CabecalhoPagina titulo={"Equipa"} backCallback={() => {
                this.props.history.goBack();
            }}/>
            <div className={"container"}>
                <p className={"section-placeholder"}>A sua equipa será atribuída no primeiro dia do evento, após o check-in</p>
            </div>
        </div>;
    }
}

export default withRouter(Equipa);